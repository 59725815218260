import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import Theme from './Theme';
import App from './App';
import './global.css';

ReactDOM.render(
  (
    <ThemeProvider theme={Theme}>
      <App />
    </ThemeProvider>),
  document.getElementById('root')
);

