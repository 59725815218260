import React from 'react';
import styled from 'styled-components';
import introText from './introText';

const StyledH1 = styled.h1`
  text-align: center;
  font-size: ${props => props.theme.fontSizes.h1};
`;

const Text = styled.p`
  max-width: 760px;
  padding: 0px 24px;
`;


const Intro = () => (
  <div>
    <StyledH1>Code Buddy Oy</StyledH1>
    <Text>{introText}</Text>
  </div>
);

export default Intro;
