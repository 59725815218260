import React from 'react';
import { Wrapper, StyledH1, Logos, Img } from './styledComponents';
import aws from './aws-logo.png'
import nodejs from './nodejs-logo.png'
import rails from './rails-logo.png'
import react from './react-logo.png'
import ansible from './ansible-logo.png'
import docker from './docker-logo.png'

const Technologies = () => (
  <Wrapper>
    <StyledH1 id="Technologies">Technologies</StyledH1>
    <Logos>
      <Img src={aws} alt="aws" />
      <Img src={nodejs} alt="nodejs" />
      <Img src={rails} alt="ruby on rails" />
      <Img src={react} alt="react" />
      <Img src={ansible} alt="ansible" />
      <Img src={docker} alt="docker" />
    </Logos>
  </Wrapper>
);

export default Technologies;
