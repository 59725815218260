import React from 'react';

import { StyledH1, Wrapper, ContactInfo, InfoLine } from './styledComponents'

const Contact = () => (
  <Wrapper>
    <StyledH1 id="Contact">Contact</StyledH1>
    <ContactInfo>
      <InfoLine>Julius Räihä</InfoLine>
      <InfoLine>julius( at )codebuddy.fi</InfoLine>
      <InfoLine>Apilatie 6 c 5</InfoLine>
      <InfoLine>01300 Vantaa</InfoLine>
      <InfoLine>Business ID: 2916169-2</InfoLine>
    </ContactInfo>
  </Wrapper>
);

export default Contact;
