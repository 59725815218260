import styled from 'styled-components';

export const StyledH1 = styled.h1`
  text-align: center;
  font-size: ${props => props.theme.fontSizes.h1};
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Logos = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const Img = styled.img`
  max-width: 15%;
  object-fit: contain;
  min-width: 100px;
  max-height: 13vh;
`;
