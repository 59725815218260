import styled from 'styled-components';

export const StyledH1 = styled.h1`
  text-align: center;
  font-size: ${props => props.theme.fontSizes.h1};
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const InfoLine = styled.span`
  padding: 4px;
`;
