import React from 'react';
import styled, { keyframes, css } from 'styled-components';

import MenuSVG from './menu.svg';

export const HeaderHeight = 32;

const ANIMATION_DURATION_MS = 200;

const animIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.5, 1);
   }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
`;

const animOut = keyframes`
  0% {
    opacity: 1;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform: scale(0.5, 1);
  }
`;

const StyledHeader = styled.header`
  height: ${HeaderHeight}px;
  text-align: end;
  position: absolute;
  width: 100%;
  z-index: 10;
`;

const Img = styled.img`
  cursor: pointer;
  padding: 16px;
`;

const Menu = styled.div`
  margin-right: 16px;
  background: white;
  flex-direction: column;
  position: absolute;
  right: 0;
  padding: 8px 16px;
  border: 1px solid black;
  border-radius: 4px;
  opacity: 0;
  transform-origin: 100% 0%;
  animation-fill-mode: forwards;
  display: none;
  ${props => props.animating && props.open && css`
    animation: ${animIn} ${ANIMATION_DURATION_MS}ms;
    display: flex;
  `}
  ${props => props.animating && !props.open && css`
    animation: ${animOut} ${ANIMATION_DURATION_MS}ms;
    display: flex;
  `}
  ${props => props.open && css`
    opacity: 1;
    display: flex;
  `}
  font-size: 20px;
  @media (max-width: 640px) {
    z-index: -1;
    width: calc(75vw - 64px);
    height: 100vh;
    right: 0;
    margin: 0;
    padding: 64px 32px;
    top: 0;
    align-items: center;
  }
`;

const StyledA = styled.a`
  color: #1d84de;
  text-decoration: none;
  @media (max-width: 640px) {
    margin-bottom: 16px;
  }
`;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.resetAnimation = this.resetAnimation.bind(this);
  }

  resetAnimation() {
    this.setState({
      animating: false,
    });
  }

  handleClick(e) {
    const nextOpenState = !this.state.menuOpen;

    if (nextOpenState !== this.state.menuOpen) {
      this.setState({
        menuOpen: nextOpenState,
        animating: true,
      }, () => setTimeout(this.resetAnimation, ANIMATION_DURATION_MS));
    }
  }


  render() {
    const { menuOpen, animating } = this.state;
    return (
      <StyledHeader>
        <Img src={MenuSVG} alt="menu toggle button" onClick={this.handleClick}/>
        <Menu animating={animating} open={menuOpen}>
          <StyledA href="#Technologies" onClick={this.handleClick}>Technologies</StyledA>
          <StyledA href="#Contact" onClick={this.handleClick}>Contact</StyledA>
        </Menu>
      </StyledHeader>
    );
  }
}

export default Header;
