import React from 'react';
import styled from 'styled-components'

import Header, { HeaderHeight } from './Header';
import Intro from './Intro';
import Technologies from './Technologies';
import Contact from './Contact';
import { Section } from './styledComponents';

const IntroSection = styled(Section)`
  height: calc(50vh - ${HeaderHeight}px);
  justify-content: center;
`;

class App extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <IntroSection>
          <Intro />
        </IntroSection>
        <Section>
          <Technologies />
        </Section>
        <Section>
          <Contact />
        </Section>
      </div>
    );
  }
}

export default App;
