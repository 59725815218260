const Theme = {
  fontSizes: {
    h1: '48px',
    h2: '36px'
  },
  colors: {
    primary: '#008E00',
    secondary: '#000000',
    disabled: '#A3A3A3'
  }
}

export default Theme;
